<template>
  <div class="w-80 bg-white border border-gray-200	p-4 rounded-lg shadow-lg ">
    <div class="flex justify-between items-center">
      <div class="flex items-center	">
        <div class="w-7 mr-3">
          <CheckCircleIcon class="text-green-400" />
        </div>
        <div>
          <div class="font-medium text-gray-600">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="w-5 ml-3">
        <XIcon
          @click="$emit('close')"
          class="text-gray-400 cursor-pointer hover:text-gray-500"
        />
      </div>
    </div>
    <div class="ml-10 mr-10  text-sm text-gray-400">
      {{ description }}
    </div>
  </div>
</template>

<script>
import CheckCircleIcon from '@/components/icons/heroicons/CheckCircle'
import XIcon from '@/components/icons/heroicons/X'
export default {
  components: {
    CheckCircleIcon,
    XIcon
  },
  props: {
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  setup() {
    return {}
  }
}
</script>
