import { firebase } from '@firebase/app'

import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyAGGMUdvTxSKzq_jQoBeSmPhbYsW53Wpf4',
  authDomain: 'full-vue-project.firebaseapp.com',
  databaseURL: 'https://full-vue-project.firebaseio.com',
  projectId: 'full-vue-project',
  storageBucket: 'full-vue-project.appspot.com',
  messagingSenderId: '927745880591',
  appId: '1:927745880591:web:5a785f1a8f2c6c4ab3e97a',
  measurementId: 'G-501JKMCMYB'
}
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const database = firebase.database()

export { auth, firebase, database }
