import router from '@/router/index'
import { firebase, auth } from '@/plugins/firebase/index.js'
import { getUserDataById } from '@/services/firebase.js'
import { LOGIN_WITH_GOOGLE, FETCH_AUTH_USER_DATA, LOGOUT } from './actions.type'
import { SET_AUTH_USER, SET_AUTH_USER_DATA } from './mutations.type'

export default {
  [LOGIN_WITH_GOOGLE]() {
    auth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(() => router.replace('dashboard'))
  },
  [FETCH_AUTH_USER_DATA]({ commit }, authUser) {
    if (authUser) {
      commit(SET_AUTH_USER, authUser)
      getUserDataById(authUser.uid).on('value', snapshot => {
        if (snapshot.val()) {
          commit(SET_AUTH_USER_DATA, snapshot.val())
        }
      })
    } else {
      commit(SET_AUTH_USER, null)
      commit(SET_AUTH_USER_DATA, null)
    }
  },
  [LOGOUT]() {
    auth.signOut().then(() => router.replace('login'))
  }
}
