export default {
  path: 'studies',
  name: 'studies',
  component: () =>
    import(
      /* webpackChunkName: "studies" */ '@/components/studies/Studies.vue'
    ),
  redirect: {
    name: 'studies.list'
  },
  children: [
    {
      path: '',
      name: 'studies.list',
      component: () =>
        import(
          /* webpackChunkName: "studies.list" */ '@/components/studies/StudiesList.vue'
        )
    },
    {
      path: 'new',
      name: 'studies.new',
      component: () =>
        import(
          /* webpackChunkName: "studies.new" */ '@/components/studies/StudiesForm.vue'
        )
    },
    {
      path: 'edit/:id',
      name: 'studies.edit',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "studies.edit" */ '@/components/studies/StudiesForm.vue'
        )
    }
  ]
}
