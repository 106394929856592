import * as mutations from './mutations.type'

export default {
  [mutations.RESET](state) {
    state.loading = false
    state.ready = false
    state.error = false
  },

  [mutations.SET_LOADING](state, value) {
    state.loading = value
  },
  [mutations.SET_READY](state, value) {
    state.ready = value
  },
  [mutations.SET_ERROR](state, value) {
    state.error = value
  }
}
