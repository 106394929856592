import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import './styles/index.css'

import { FETCH_AUTH_USER_DATA } from '@/store/modules/auth/actions.type'
import { AUTH_STORE_NAME } from '@/store//modules/auth/index.js'

import { firebase } from '@/plugins/firebase/index.js'
let app = ''

firebase.auth().onAuthStateChanged(authUser => {
  store.dispatch(`${AUTH_STORE_NAME}/${FETCH_AUTH_USER_DATA}`, authUser)
  if (!app) {
    app = createApp(App)
      .use(store)
      .use(router)
      .mount('#app')
  }
})
