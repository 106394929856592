<template>
  <router-view v-slot="{ Component }">
    <TransitionComponent>
      <component :is="Component" />
    </TransitionComponent>
  </router-view>
  <div class="fixed bottom-0 right-0 m-5">
    <TransitionComponent>
      <Toast
        :key="notification.id"
        v-for="notification in notifications"
        :title="notification.title"
        :description="notification.desciption"
        :type="notification.type"
        class="mt-5"
        @close="deleteNotification(notification.id)"
      />
    </TransitionComponent>
  </div>
  <div id="portal"></div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { NOTIFICATIONS_STORE_NAME } from '@/store/modules/notifications'
import { DELETE_NOTIFICATION } from '@/store/modules/notifications/actions.type'

import TransitionComponent from '@/components/common/transition/Transition.vue'
import Toast from '@/components/Toast.vue'
export default {
  components: {
    Toast,
    TransitionComponent
  },
  setup() {
    const store = useStore()
    const notifications = computed(
      () => store.getters[NOTIFICATIONS_STORE_NAME + '/notifications']
    )
    function deleteNotification(id) {
      store.dispatch(NOTIFICATIONS_STORE_NAME + '/' + DELETE_NOTIFICATION, id)
    }
    return {
      notifications,
      deleteNotification
    }
  }
}
</script>
