import * as Mutations from './mutations.type'

export default {
  [Mutations.PUSH_NOTIFICATION](state, data) {
    state.notifications.unshift(data)
  },
  [Mutations.DELETE_NOTIFICATION](state, id) {
    state.notifications = state.notifications.filter(
      notification => notification.id !== id
    )
  }
}
