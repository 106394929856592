import { createWebHistory, createRouter } from 'vue-router'
import { auth } from '@/plugins/firebase/index.js'
import Studies from './studies.js'
import Courses from './courses.js'
import Experiences from './experiences.js'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'banner',
        name: 'banner',
        component: () =>
          import(
            /* webpackChunkName: "bannerForm" */ '@/components/BannerForm.vue'
          )
      },
      {
        path: 'about-me',
        name: 'about-me',
        component: () =>
          import(
            /* webpackChunkName: "aboutMeForm" */ '@/components/AboutMeForm.vue'
          )
      },
      {
        path: 'social-networks',
        name: 'social-networks',
        component: () =>
          import(
            /* webpackChunkName: "socialNetworksForm" */ '@/components/SocialNetworksForm.vue'
          )
      },
      Studies,
      Courses,
      Experiences
    ]
  },
  {
    path: '/profile/:email',
    name: 'profile',
    props: true,
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),

    meta: { breadCrumb: 'Profile', isPublic: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isPublic = to.matched.some(record => record.meta.isPublic)

  if (requiresAuth && !auth.currentUser) next('/login')
  else if (!requiresAuth && auth.currentUser && !isPublic) next('/dashboard')
  else next()
})

export default router
