import { FETCH_DATA_BY_EMAIL } from './actions.type'
import { SET_PROFILE_DATA } from './mutations.type'
import { geProfileDataByemail } from '@/services/firebase.js'

export default {
  [FETCH_DATA_BY_EMAIL]({ commit }, email) {
    geProfileDataByemail(email).on('value', function(snapshot) {
      const data = snapshot.val()
      const profileData = data[Object.keys(data)[0]]
      commit(SET_PROFILE_DATA, profileData)
    })
  }
}
