import { getUserDataById } from '@/services/firebase'
import * as Actions from './actions.type'
import * as Mutations from './mutations.type'

export default {
  async [Actions.CREATE_COURSE]({ rootGetters, commit, getters }, data) {
    try {
      commit(Mutations.RESET)
      commit(Mutations.SET_LOADING, true)

      await getUserDataById(rootGetters['auth/authUserUid'])
        .child(getters.table)
        .push(data)
      commit(Mutations.SET_LOADING, false)
      commit(Mutations.SET_READY, true)
    } catch (error) {
      commit(Mutations.SET_LOADING, false)
      commit(Mutations.SET_ERROR, true)
    }
  },

  async [Actions.UPDATE_COURSE]({ rootGetters, commit, getters }, data) {
    try {
      commit(Mutations.RESET)
      commit(Mutations.SET_LOADING, true)
      await getUserDataById(rootGetters['auth/authUserUid'])
        .child(getters.table)
        .child(data.id)
        .update(data)
      commit(Mutations.SET_LOADING, false)
      commit(Mutations.SET_READY, true)
    } catch (error) {
      commit(Mutations.SET_LOADING, false)
      commit(Mutations.SET_ERROR, true)
    }
  },

  async [Actions.DELETE_COURSE]({ rootGetters, commit, getters }, key) {
    try {
      commit(Mutations.RESET)
      commit(Mutations.SET_LOADING, true)
      await getUserDataById(rootGetters['auth/authUserUid'])
        .child(getters.table)
        .child(key)
        .remove()
      commit(Mutations.SET_LOADING, false)
      commit(Mutations.SET_READY, true)
    } catch (error) {
      commit(Mutations.SET_LOADING, false)
      commit(Mutations.SET_ERROR, true)
    }
  }
}
