export default {
  path: 'courses',
  name: 'courses',
  component: () =>
    import(
      /* webpackChunkName: "courses" */ '@/components/courses/Courses.vue'
    ),
  redirect: {
    name: 'courses.list'
  },
  children: [
    {
      path: '',
      name: 'courses.list',
      component: () =>
        import(
          /* webpackChunkName: "courses.list" */ '@/components/courses/CoursesList.vue'
        )
    },
    {
      path: 'new',
      name: 'courses.new',
      component: () =>
        import(
          /* webpackChunkName: "courses.new" */ '@/components/courses/CoursesForm.vue'
        )
    },
    {
      path: 'edit/:id',
      name: 'courses.edit',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "courses.edit" */ '@/components/courses/CoursesForm.vue'
        )
    }
  ]
}
