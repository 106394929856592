import { getUserDataById } from '@/services/firebase'
import * as Actions from './actions.type'
import * as Mutations from './mutations.type'

export default {
  async [Actions.UPDATE_SOCIAL_NETWORKS]({ rootGetters, commit }, data) {
    try {
      commit(Mutations.RESET)
      commit(Mutations.SET_LOADING, true)
      console.log(data)
      await getUserDataById(rootGetters['auth/authUserUid'])
        .child('socialNetworks')
        .update(data)
      commit(Mutations.SET_LOADING, false)
      commit(Mutations.SET_READY, true)
    } catch (error) {
      commit(Mutations.SET_LOADING, false)
      commit(Mutations.SET_ERROR, true)
    }
  }
}
