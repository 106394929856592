import { createStore } from 'vuex'
import authStore, { AUTH_STORE_NAME } from './modules/auth'
import profileStore, { PROFILE_STORE_NAME } from './modules/profile'
import bannerStore, { BANNER_STORE_NAME } from './modules/banner'
import socialNetworksStore, {
  SOCIAL_NETWORKS_STORE_NAME
} from './modules/socialNetworks'
import notificationsStore, {
  NOTIFICATIONS_STORE_NAME
} from './modules/notifications'
import aboutMe, { ABOUT_ME_STORE_NAME } from './modules/aboutMe'
import studies, { STUDIES_STORE_NAME } from './modules/studies'
import courses, { COURSES_STORE_NAME } from './modules/courses'
import experiences, { EXPERIENCES_STORE_NAME } from './modules/experiences'

export default createStore({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    [AUTH_STORE_NAME]: authStore,
    [PROFILE_STORE_NAME]: profileStore,
    [BANNER_STORE_NAME]: bannerStore,
    [SOCIAL_NETWORKS_STORE_NAME]: socialNetworksStore,
    [NOTIFICATIONS_STORE_NAME]: notificationsStore,
    [ABOUT_ME_STORE_NAME]: aboutMe,
    [STUDIES_STORE_NAME]: studies,
    [COURSES_STORE_NAME]: courses,
    [EXPERIENCES_STORE_NAME]: experiences
  }
})
