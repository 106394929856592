import * as Actions from './actions.type'
import * as Mutations from './mutations.type'

export default {
  [Actions.PUSH_NOTIFICATION]({ commit, dispatch }, data) {
    data.id = new Date().getTime()
    commit(Mutations.PUSH_NOTIFICATION, data)
    setTimeout(() => {
      dispatch(Actions.DELETE_NOTIFICATION, data.id)
    }, 5000)
  },
  [Actions.DELETE_NOTIFICATION]({ commit }, id) {
    commit(Mutations.DELETE_NOTIFICATION, id)
  }
}
