<template>
  <transition
    v-if="!isGroup"
    :enter-active-class="transition['enter-active-class']"
    :enter-from-class="transition['enter-from-class']"
    :enter-to-class="transition['enter-to-class']"
    :leave-active-class="transition['leave-active-class']"
    :leave-from-class="transition['leave-from-class']"
    :leave-to-class="transition['leave-to-class']"
    :mode="mode"
  >
    <slot />
  </transition>
  <transition-group
    v-else
    :tag="tag"
    :enter-active-class="transition['enter-active-class']"
    :enter-from-class="transition['enter-from-class']"
    :enter-to-class="transition['enter-to-class']"
    :leave-active-class="transition['leave-active-class']"
    :leave-from-class="transition['leave-from-class']"
    :leave-to-class="transition['leave-to-class']"
  >
    <slot />
  </transition-group>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'fade',
      validator(value) {
        // The value must match one of these strings
        return ['fade', 'slide-fade'].includes(value)
      }
    },
    isGroup: {
      type: Boolean
    },
    mode: {
      type: String,
      default: 'out-in'
    },
    tag: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    const transitions = {
      fade: {
        'enter-active-class': 'transition-opacity  duration-500',
        'enter-from-class': 'opacity-0',
        'enter-to-class': 'opacity-100',
        'leave-active-class': 'transition-opacity duration-500',
        'leave-from-class': 'opacity-100',
        'leave-to-class': 'opacity-0'
      },
      'slide-fade': {
        'enter-active-class': 'transition-all duration-300 ease-linear	',
        'enter-from-class': 'opacity-0  transform translate-x-2.5',
        'enter-to-class': 'opacity-100 transform  translate-x-0',
        'leave-active-class': 'transition-all duration-300 ease-linear	',
        'leave-from-class': 'opacity-100 transform ranslate-x-0',
        'leave-to-class': 'opacity-0 transform -translate-x-2.5	'
      }
    }
    return { transition: transitions[props.name] }
  }
}
</script>
