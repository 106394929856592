import { SET_AUTH_USER, SET_AUTH_USER_DATA } from './mutations.type'

export default {
  [SET_AUTH_USER](state, authUser) {
    state.authUser = authUser
  },
  [SET_AUTH_USER_DATA](state, authUserData) {
    state.authUserData = authUserData
  }
}
