import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export const STUDIES_STORE_NAME = 'studies'

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
