export default {
  path: 'experiences',
  name: 'experiences',
  component: () =>
    import(
      /* webpackChunkName: "experiences" */ '@/components/experiences/Experiences.vue'
    ),
  redirect: {
    name: 'experiences.list'
  },
  children: [
    {
      path: '',
      name: 'experiences.list',
      component: () =>
        import(
          /* webpackChunkName: "experiences.list" */ '@/components/experiences/ExperiencesList.vue'
        )
    },
    {
      path: 'new',
      name: 'experiences.new',
      component: () =>
        import(
          /* webpackChunkName: "experiences.new" */ '@/components/experiences/ExperiencesForm.vue'
        )
    },
    {
      path: 'edit/:id',
      name: 'experiences.edit',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "experiences.edit" */ '@/components/experiences/ExperiencesForm.vue'
        )
    }
  ]
}
